<template>
  <VDialog
    content-class="cross__dialog"
    v-model="visibility"
    max-width="600px"
    :persistent="isChanged"
    @click:outside="showConfirmDialog"
    scrollable
  >
    <VCard :height="$vuetify.breakpoint.smAndDown ? '500' : ''" :loading="loadingCard" :disabled="loadingCard">
      <div class="cross__inner">
        <VBtn fab small color="white" elevation="0" @click="showConfirmDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <VCardTitle>
        <span class="elispsis" :class="{ 'text-caption': $vuetify.breakpoint.xs }">{{
          $t("moveList.request_to_move_items")
        }}</span>
      </VCardTitle>
      <VCardText>
        <VContainer>
          <div class="text-center" v-if="loading">
            <VProgressCircular :size="50" color="primary" indeterminate></VProgressCircular>
          </div>
          <div class="" v-else>
            <VRow class="pb-0 mb-0">
              <v-slide-x-reverse-transition>
                <VCol cols="12" md="12" sm="12" v-if="accessRole">
                  <v-autocomplete
                    :items="depots"
                    :item-value="'uuid'"
                    :item-text="'name'"
                    outlined
                    :label="$t('form.storage')"
                    :no-data-text="$t('table.noData')"
                    dense
                    @change="filterItemList"
                    v-model="task.deport_uuid"
                    :error-messages="uuidErrors"
                    deletable-chips
                    small-chips

                  />
                </VCol>
            </v-slide-x-reverse-transition>
              <VCol cols="12" md="12" sm="12">
                <v-autocomplete
                  :items="sectorsList"
                  :item-value="'uuid'"
                  :item-text="'name'"
                  outlined
                  :label="$t('form.sector')"
                  :no-data-text="$t('table.noData')"
                  dense
                  hide-details="auto"
                  @change="filterItemListBySector"
                  v-model="task.sector_uuid"
                  :error-messages="uuidErrors"
                  deletable-chips
                  small-chips
                  :disabled="loadingSectors"
                />
              </VCol>
              <VCol cols="12" md="12" sm="12">
                <v-autocomplete
                  :items="cellsList"
                  :item-value="'uuid'"
                  :item-text="'name'"
                  outlined
                  :label="$t('form.cell')"
                  :no-data-text="$t('table.noData')"
                  dense
                  @change="filterItemListByCell"
                  v-model="task.cell_uuid"
                  :error-messages="uuidErrors"
                  hide-details="auto"
                  deletable-chips
                  small-chips
                  :disabled="loadingCells"
                />
              </VCol>
              <VCol cols="12">
                <v-autocomplete
                  :disabled="task.deport_uuid.length < 1 && accessRole"
                  outlined
                  dense
                  label="Item"
                  :no-data-text="$t('table.noData')"
                  :items="itemsList"
                  :item-value="'uuid'"
                  :item-text="
                    products => `${products.ns_code} – ${products.product_details.name}  `
                  "
                  :hide-details="!uuidItemErrors.length"
                  v-model="task.uuid_item"
                  :error-messages="uuidItemErrors"
                  deletable-chips
                  small-chips
                />
              </VCol>
              <VCol cols="12">
                <v-autocomplete
                  :disabled="task.deport_uuid.length < 1 && accessRole"
                  outlined
                  dense
                  small-chips
                  :label="$t('moveList.where')"
                  :no-data-text="$t('table.noData')"
                  :items="cellsList"
                  :item-value="'uuid'"
                  :item-text="'name'"
                  chips
                  :hide-details="!uuidDestCellErrors.length"
                  v-model="task.uuid_dest_cell"
                  :error-messages="uuidDestCellErrors"
                  deletable-chips
                >
                  <template slot="selection" slot-scope="data">
                    <VChip small close @click:close="task.uuid_dest_cell = ''">
                      {{ `${data.item.name}` }}
                    </VChip>
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ `${data.item.name}` }}
                  </template>
                </v-autocomplete>
              </VCol>
              <VCol cols="12">
                <VTextarea
                  outlined
                  dense
                  :label="$t('form.description')"
                  required
                  no-resize
                  counter
                  maxlength="255"
                  rows="2"
                  hide-details
                  v-model="task.description"
                ></VTextarea>
              </VCol>
            </VRow>
          </div>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0 container">
          <VCol cols="6">
            <VBtn
              dark
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
              @click="onCancel"
              block
              class="text-white text-transform-none br-10 w-100"
            >
              {{ $t("btn.cancel") }}
            </VBtn>
          </VCol>
          <VCol cols="6">
            <VBtn
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
              block
              class="success-bg text-white text-transform-none br-10 w-100"
              @click="onCreate"
              :disabled="!isChanged"
            >
              {{ $t("btn.create") }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onCancel"
    />
  </VDialog>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import language from "../../mixins/language";
import notifications from "../../mixins/notifications";
import user from "../../mixins/user";
import EventBus from "../../events/EventBus";
import depotService from "../../services/request/depot/depotService";
import { mapGetters, mapActions } from "vuex";
import * as getterTypes from "@/store/modules/moveList/types/getters";
import * as actionTypes from "@/store/modules/moveList/types/actions";

export default {
  name: "AddMoveListItemDialog",
  mixins: [language, notifications, validationMixin, user],
  validations: {
    task: {
      // count: { required, minValue: minValue(1) },
      uuid_dest_cell: { required },
      uuid_item: { required },
      deport_uuid: {
        // eslint-disable-next-line func-names
        required: requiredIf(function() {
          return this.accessRole;
        })
      }
    }
  },
  components: {
    ConfirmLeftDialog
  },
  data: () => ({
    loading: false,
    accessRole: false,
    itemsList: [],
    cellsList: [],
    sectorsList: [],
    visibleConfirm: false,
    isChanged: false,
    task: {
      count: 1,
      uuid_dest_cell: "",
      description: "",
      deport_uuid: "",
      cell_uuid: "",
      sector_uuid: "",
      uuid_item: ""
    },
    loadingCells: false,
    loadingProducts: false,
    loadingSectors:false,
    loadingCard:false,
  }),

  async mounted() {
    this.loadingCard = true
    this.loadingCells = true;
    await this.fetchCells();
    this.loadingCells = false;

    this.loadingProducts = true;
    await this.fetchProducts();
    this.loadingProducts = false;

    this.loadingSectors = true;
    await this.fetchSectors();
    this.loadingSectors = false;

    this.updateRoleInfo();
    this.updateItems();
    this.updateCells();
    this.updateSectors();
    this.loadingCard = false
  },
  methods: {
    ...mapActions("moveList", {
      fetchCells: actionTypes.FETCH_CELLS,
      fetchProducts: actionTypes.FETCH_PRODUCTS,
      fetchSectors: actionTypes.FETCH_SECTORS,
      fetchDeports: actionTypes.FETCH_DEPORTS
    }),
    showConfirmDialog() {
      if (this.isChanged) {
        this.visibleConfirm = true;
      } else {
        this.onCancel();
      }
    },

    onCancel() {
      this.visibility = false;
      this.task.uuid_dest_cell = "";
      this.task.description = "";
      this.task.uuid_item = "";
      this.task.deport_uuid = "";
      this.task.sector_uuid = "";
      this.task.cell_uuid = "";
      this.$v.$reset();
    },
    async filterItemList() {
      try {
        this.itemsList = await depotService.getAllItems({
          uuid_deport: this.task.deport_uuid,
          id_state: 810,
          orderby: "ns_code"
        });
        this.cellsList = await depotService.getCellsList({
          sector_details__uuid_deport: this.task.deport_uuid
          // id_state: 810,
        });
        this.sectorsList = await depotService.getSectorsList({
          uuid_deport: this.task.deport_uuid
          // id_state: 810,
        });
        // orderDialog(this.sectorsList)
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    async filterItemListBySector() {
      try {
        this.itemsList = await depotService.getAllItems({
          cell_details__uuid_sector: this.task.sector_uuid,
          id_state: 810,
          orderby: "ns_code"
        });
        this.cellsList = await depotService.getCellsList({
          uuid_sector: this.task.sector_uuid
          // id_state: 810,
        });
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    async filterItemListByCell() {
      try {
        this.itemsList = await depotService.getAllItems({
          uuid_cell: this.task.cell_uuid,
          id_state: 810,
          orderby: "ns_code"
        });
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    updateItems() {
      this.itemsList = this.items;
    },
    updateCells() {
      this.cellsList = this.cells;
    },
    updateSectors() {
      this.sectorsList = this.sectors;
    },
    updateRoleInfo() {
      if (this.permissions.can_create_move_list_deport) {
        this.fetchDeports()
        this.accessRole = true;
        // this.validations.user.deport_uuid.required = true;
      }
    },
    async onCreate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.count = 1;
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append("count", this.task.count);
          formData.append("description", this.task.description);
          formData.append("uuid_item", this.task.uuid_item);
          if (this.accessRole) {
            formData.append("uuid_deport", this.task.deport_uuid);
          }
          formData.append("uuid_dest_cell", this.task.uuid_dest_cell);
          await depotService.addMoveListItem(formData);
          this.setSuccessNotification(this.$t("moveList.task_was_created_successfully"));
          EventBus.$emit("move-item-created");
          this.clearTask();
          this.$v.$reset();
          this.visibility = false;
          this.loading = false;
        } catch (e) {
          this.loading = false;
          this.setErrorNotification(this.$t("moveList.alreadyExist"));
        }
      }
    },
    clearTask() {
      Object.keys(this.task).forEach(key => {
        this.task[key] = "";
      });
    }
  },
  watch: {
    role: "updateRoleInfo",
    items: "updateItems",
    cells: "updateCells",
    sectors: "updateSectors",
    task: {
      deep: true,
      handler() {
        const values = Object.values(this.task);
        if (values.filter(item => item).length > 1) {
          this.isChanged = true;
        } else {
          this.isChanged = false;
        }
      }
    }
  },
  props: {
    visible: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters("moveList", {
      cells: getterTypes.GET_CELLS,
      products: getterTypes.GET_PRODUCTS,
      sectors: getterTypes.GET_SECTORS,
      depots: getterTypes.GET_DEPORTS
    }),
    uuidErrors() {
      const errors = [];
      if (!this.$v.task.deport_uuid.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.task.deport_uuid.required &&
        errors.push(this.language.isRequired(this.language.DEPOT_UUID));
      return errors;
    },
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      }
    },
    countErrors() {
      const errors = [];
      if (!this.$v.task.count.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.task.count.required && errors.push(this.$t("form.errors.AmountRequired"));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.task.count.minValue &&
        errors.push(`${this.$t("table.amount")} ${this.$t("form.errors.AtLeastOne")}`);
      return errors;
    },
    uuidItemErrors() {
      const errors = [];
      if (!this.$v.task.uuid_item.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.task.uuid_item.required && errors.push(this.$t("form.errors.ItemRequired"));
      return errors;
    },
    uuidDestCellErrors() {
      const errors = [];
      if (!this.$v.task.uuid_dest_cell.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.task.uuid_dest_cell.required && errors.push(this.$t("form.errors.WhereRequired"));
      return errors;
    }
  }
};
</script>

<style scoped></style>
